import React, { Component } from "react";
import UserService from "../services/user.service";
import imageBack from '../img/n9y28w7x_banner.png'
import "../App.css";
import AuthService from "../services/auth.service";

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.handleBtnClick = this.handleBtnClick.bind(this);
        this.state = {
            content: ""
        };
    }
    componentDidMount() {
        UserService.getPublicContent().then(
            response => {
                this.setState({
                    content: response.data
                });
            },
            error => {
                this.setState({
                    content:
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString()
                });
            }
        );
    }

    handleBtnClick(e){
        const user = AuthService.getCurrentUser();
        if(user != null && user != undefined){
            this.props.history.push("/message");
        }else{
            this.props.history.push("/login");
        }
    }

    render() {
        return (
            <div className="container">
                <header className="jumbotron">
                    {/*<h3>{this.state.content}</h3>*/}
                </header>
                <div className="col-lg-12 home-back-image-div text-center">
                    <img src={imageBack} className="back-image img-fluid" />
                    <br/>
                    <button className="btn btn-success" onClick={this.handleBtnClick}>Get Started</button>
                </div>
            </div>
        );
    }
}