import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../services/auth.service";
import "../profile.css";
import imageAvatar from "../img/avatardefault_92824.webp";

export default class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: null,
            userReady: false,
            currentUser: { username: "" }
        };
    }

    componentDidMount() {
        const currentUser = AuthService.getCurrentUser();

        if (!currentUser) this.setState({ redirect: "/home" });
        this.setState({ currentUser: currentUser, userReady: true })
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        const { currentUser } = this.state;

        return (
            // <div className="container">
            //     {(this.state.userReady) ?
            //         <div>
            //             <header className="jumbotron">
            //                 <h3>
            //                     <strong>{currentUser.username.toUpperCase()}</strong> Profile
            //                 </h3>
            //             </header>
            //             <br/>
            //             <p>
            //                 <strong>Username:</strong>{" "}
            //                 {currentUser.username}
            //             </p>
            //             <p>
            //                 <strong>Email:</strong>{" "}
            //                 {currentUser.email}
            //             </p>
            //             <strong>Authorities:</strong>
            //             <ul>
            //                 {currentUser.roles &&
            //                     currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
            //             </ul>
            //         </div>: null}
            // </div>

            <div className="main-content">
                <div className="container mt-7">
                    {/*<h2 className="mb-5">Profile Card</h2>*/}
                        <div className="row">
                        <div className="col-xl-8 m-auto order-xl-2 mb-5 mb-xl-0">
                            <div className="card card-profile shadow">
                                <div className="row justify-content-center">
                                    <div className="col-lg-3 order-lg-2">
                                        <div className="card-profile-image">
                                            <a href={"#"} >
                                                <img
                                                    src={imageAvatar}
                                                    className="rounded-circle"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                                    <div className="d-flex justify-content-between">
                                    </div>
                                </div>
                                <div className="card-body pt-0 pt-md-4">
                                    <div className="row">
                                        <div className="col">
                                            <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                                                <div>
                                                    <span className="heading">0</span>
                                                    <span className="description">Sent Messages</span>
                                                </div>
                                                <div>
                                                    <span className="heading">0</span>
                                                    <span className="description">Success Messages</span>
                                                </div>
                                                <div>
                                                    <span className="heading">0</span>
                                                    <span className="description">Messages Not Sent</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <h3>
                                            {currentUser.username}
                                        </h3>
                                        <div className="h5 font-weight-300">
                                           {currentUser.email}
                                        </div>
                                        <div className="h5 mt-4">
                                            {currentUser.roles &&
                                                currentUser.roles.map((role, index) =>
                                                    <span key={index}>{role}</span>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}