import axios from "axios";
import * as constants from '../config/constanst';
import authHeader from './auth-header';
class CampaignService {

    sendCampaign(campaignName,txtMsg,campaignURL,campaignGroup,target){
        return axios
            .post(constants.API_END_POINT + constants.SEND_CAMPAIGN_API, {
                campaignName: campaignName,
                txtMsg: txtMsg,
                campaignURL: "",
                campaignGroup: campaignGroup,
                target:target
            },{headers: authHeader()});

    }

    getAllCampaigns(){
        return axios
            .get(
                constants.API_END_POINT + constants.GET_ALL_CAMPAIGNS_API,
                {headers: authHeader()});
    }

    getCampaignListSize(group,geo){
        return axios
            .get(
                constants.API_END_POINT + constants.GET_CAMPAIGN_LIST_SIZE_API+"?group="+group+"&geo="+geo,
                {headers: authHeader()});
    }

}
export default new CampaignService();