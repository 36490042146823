import axios from "axios";
import * as constants from '../config/constanst';
import authHeader from './auth-header';
class MessageService {

    sendMessage(mobileNo,orderNo,text,username){
        return axios
            .post(constants.API_END_POINT + constants.SEND_MESSAGE_API, {
                orderNo: orderNo,
                messageText: text,
                mobileNo: mobileNo,
                sendUser: username
            },{headers: authHeader()});

    }

    getAllMessages(){
        return axios
            .get(
                constants.API_END_POINT + constants.GET_ALL_MESSAGES_API,
                {headers: authHeader()});
    }
}
export default new MessageService();