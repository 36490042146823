import axios from 'axios';
import authHeader from './auth-header';
import * as constants from '../config/constanst';
class UserService {
    getPublicContent() {
        return axios.get(constants.API_END_POINT +constants.TEST_API+ 'all');
    }
    getUserBoard() {
        return axios.get(constants.API_END_POINT +constants.TEST_API+ 'user', { headers: authHeader() });
    }
    getModeratorBoard() {
        return axios.get(constants.API_END_POINT +constants.TEST_API+ 'mod', { headers: authHeader() });
    }
    getAdminBoard() {
        return axios.get(constants.API_END_POINT +constants.TEST_API+ 'admin', { headers: authHeader() });
    }
}
export default new UserService();