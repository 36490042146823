import React, { Component,useState } from "react";
import UserService from "../services/user.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import MessageService from "../services/message.service";
import AuthService from "../services/auth.service";
import EventBus from "../common/EventBus";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

function AlertDismissible() {
    return (
            <Alert show={true} variant="success">
                <Alert.Heading>Message Sent</Alert.Heading>
                <hr />
                <div className="d-flex justify-content-end">
                    <Button onClick={() => window.location.reload()} variant="outline-success">
                        New Message +
                    </Button>
                </div>
            </Alert>
    );
}

function SessionError() {
    return (
        <Alert show={true} variant="danger">
            <Alert.Heading>Session Timed out.Please login again..</Alert.Heading>
            <hr />
            <div className="d-flex justify-content-end">
                <Button onClick={() => window.location.reload()} variant="outline-success">
                    Login
                </Button>
            </div>
        </Alert>
    );
}

export default class BoardMessage extends Component {
    constructor(props) {
        super(props);
        this.handleMessageSend = this.handleMessageSend.bind(this);
        this.onChangeMobileNo = this.onChangeMobileNo.bind(this);
        this.onChangeOrderNo = this.onChangeOrderNo.bind(this);
        this.onChangeMessageText = this.onChangeMessageText.bind(this);
        this.logOut = this.logOut.bind(this);

        this.state = {
            content: "",
            mobileNo: "",
            orderNo: "",
            messageText: "",
            loading: false,
            msgLength: 0,
            isSent: false,
            isSessionError: false
        };
    }

    logOut() {
        AuthService.logout();
        this.setState({
            showModeratorBoard: false,
            showAdminBoard: false,
            currentUser: undefined,
        });
        this.props.history.push("/login");
        window.location.reload();
    }

    componentDidMount() {
        UserService.getUserBoard().then(
            response => {
                this.setState({
                    content: response.data
                });
            },
            error => {
                this.setState({
                    content:
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString()
                });

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                    this.props.history.push("/login");
                }
            }
        );
    }

    onChangeMobileNo(e){
        this.setState({
            mobileNo: e.target.value
        });
    }

    onChangeOrderNo(e){
        this.setState({
            orderNo: e.target.value
        });
    }


    onChangeMessageText(e){
        this.setState({
            messageText: e.target.value,
            msgLength: e.target.value.length
        });

    }


    handleMessageSend(e){
        e.preventDefault();
        this.form.validateAll();
        if (this.checkBtn.context._errors.length === 0) {
            if(this.state.messageText.length > 0) {

                const user = AuthService.getCurrentUser();
                this.setState({
                    message: "",
                    loading: true
                });
                MessageService.sendMessage(this.state.mobileNo, this.state.orderNo, this.state.messageText,user.username).then(
                    response => {
                        console.log(response.data);
                        if(response.data.status === "SUCCESS"){
                            this.setState({
                                loading:false,
                                isSent: true
                            });
                        }else{
                            this.setState({
                                loading: false,
                                message: response.data.msg
                            });
                        }
                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        this.setState({
                            loading: false,
                            message: resMessage
                        });
                        if(resMessage.indexOf("code 401")){
                            this.setState({
                                isSessionError : true
                            });
                        }
                    }
                );
            }
        }
    }

    render() {
        return (
            <div className="container">
                <header className="jumbotron">
                    {/*<h3>Send Message</h3>*/}
                </header>
                <div className="col-md-12">
                    <div className="card card-container">
                        <img
                            src="//cdn-icons-png.flaticon.com/512/234/234129.png"
                            alt="profile-img"
                            className="profile-img-card"
                        />
                        {this.state.isSessionError && (
                            <SessionError />
                        )}
                        {this.state.isSent && (
                            <AlertDismissible/>
                            )}
                        {!this.state.isSent && !this.state.isSessionError && (
                        <Form
                            onSubmit={this.handleMessageSend}
                            ref={c => {
                                this.form = c;
                            }}
                        >
                            <div className="form-group">
                                <label htmlFor="mobileNo">Mobile No</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="mobileNo"
                                    value={this.state.mobileNo}
                                    onChange={this.onChangeMobileNo}
                                    validations={[required]}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="orderNo">Order No</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="orderNo"
                                    value={this.state.orderNo}
                                    onChange={this.onChangeOrderNo}
                                    validations={[required]}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="messageTxt">Message Text</label>
                                <textarea
                                    className="form-control"
                                    name="messageTxt"
                                    value={this.state.messageText}
                                    onChange={this.onChangeMessageText}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="textLength">Message Length : {this.state.msgLength}</label>
                            </div>
                            <br/>
                            <div className="form-group" style={{textAlign:"center"}}>
                                <button
                                    className="btn btn-danger btn-block"
                                    disabled={this.state.loading}
                                >
                                    {this.state.loading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    &nbsp;
                                    <span>Send Message</span>
                                </button>
                            </div>
                            {this.state.message && (
                                <div className="form-group">
                                    <div className="alert alert-danger" role="alert">
                                        {this.state.message}
                                    </div>
                                </div>
                            )}
                            <CheckButton
                                style={{ display: "none" }}
                                ref={c => {
                                    this.checkBtn = c;
                                }}
                            />
                        </Form>
                            )}
                    </div>
                </div>
            </div>
        );
    }
}