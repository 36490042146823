export const API_END_POINT = "https://api-sms-tool.edclinic.co.uk/api";
//export const API_END_POINT = "http://localhost:5000/api";
export const GET_TOKEN_API = "/auth/login";
export const SIGNUP_API = "/auth/signup";

export const SEND_MESSAGE_API = "/message/send";
export const GET_ALL_MESSAGES_API = "/message/get_send_list";
export const TEST_API = "/test/";
export const SEND_CAMPAIGN_API = "/sms_campaign/send"
export const GET_ALL_CAMPAIGNS_API = "/sms_campaign/sent_campaigns"
export const GET_CAMPAIGN_LIST_SIZE_API = "/customer/get_list_size"