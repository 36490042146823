import React, { Component } from "react";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import MessageService from "../services/message.service";

export default class BoardMessageList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: "",
            smsList:[]
        };
    }

    componentDidMount() {
        UserService.getAdminBoard().then(
            response => {
                this.setState({
                    content: response.data
                });
            },
            error => {
                this.setState({
                    content:
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString()
                });

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                    this.props.history.push("/login");
                }
            }
        );

        MessageService.getAllMessages().then(
            response => {
                this.setState({
                    smsList: response.data
                });
            },
            error => {

            });

    }
    render() {
        console.log(this.state.smsList);
        const i = 0;
        return (
            <div className="container">
                <header className="jumbotron">
                    <h3>Sent Messages Dashboard</h3>
                </header>
                <div>
                    <table className="table">
                        <thead className="thead-dark">
                        <tr>
                            <td>#</td>
                            <td>Mobile No</td>
                            <td>Order No</td>
                            <td>Send Text</td>
                            <td>Delivery Status</td>
                            <td>Date Sent</td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.smsList.map(
                                (sms,index) =>
                                    <tr key = {sms.id}>
                                        <td> { index+1} </td>
                                        <td> { sms.mobileNo} </td>
                                        <td> { sms.orderNo} </td>
                                        <td> {sms.messageText === null ? "NA" : sms.messageText}</td>
                                        <td> {sms.sent ? "Sent" : "Not Sent" }</td>
                                        <td> {sms.timeCreated}</td>
                                    </tr>
                            )
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}