import React, { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AuthService from "./services/auth.service";
import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Profile from "./components/profile.component";
import BoardMessage from "./components/board-message.component";
import BoardModerator from "./components/board-moderator.component";
import logo from './img/logo_350X150.png'
import { useHistory } from "react-router-dom";
import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";
import BoardCampaign from "./components/board-campaign";
import BoardMessageList from "./components/board-message_list.component";
import BoardCampaignList from "./components/board-campaign_list.component";


class App extends Component {

  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }

    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
    this.props.history.push("/login");
    window.location.reload();
  }

  render() {


    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;

    return (
        <div>
          <nav className="navbar navbar-expand navbar-dark bg-dark">
            <Link to={"/"} className="navbar-brand">
              <img src={logo} alt="Logo" className="logo"/>
              &nbsp;
              Lassana Message Tool
            </Link>
            <div className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link to={"/home"} className="nav-link">
                  Home
                </Link>
              </li>

              {showModeratorBoard && (
                  <li className="nav-item">
                    <Link to={"/mod"} className="nav-link">
                      Moderator Board
                    </Link>
                  </li>
              )}

              {showAdminBoard && (
                  <>
                  <li className="nav-item">
                    <Link to={"/message_list"} className="nav-link">
                      Message List
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={"/campaign_list"} className="nav-link">
                      Campaign List
                    </Link>
                  </li>
                  </>
              )}

              {currentUser && (
                  <li className="nav-item">
                    <Link to={"/message"} className="nav-link">
                      Send Message
                    </Link>
                  </li>
              )}

              {currentUser && (
                  <li className="nav-item">
                    <Link to={"/send_campaign"} className="nav-link">
                      Send Campaign
                    </Link>
                  </li>
              )}
            </div>

            {currentUser ? (
                <div className="navbar-nav user-con-nav">
                  <li className="nav-item">
                    <Link to={"/profile"} className="nav-link">
                      {currentUser.username}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a href="/login">
                      <button className="btn btn-danger" onClick={this.logOut}>
                        Logout
                      </button>
                    </a>
                    {/*<a href="/login" className="nav-link" onClick={this.logOut}>*/}
                    {/*  LogOut*/}
                    {/*</a>*/}
                  </li>
                </div>
            ) : (
                <div className="navbar-nav user-con-nav">
                  <li className="nav-item">
                    <a href="/login">
                      <button className="btn btn-default ">
                        Login
                      </button>
                    </a>
                  </li>
                </div>
            )}
          </nav>

          <div className="container mt-3">
            <Switch>
              <Route exact path={["/", "/home"]} component={Home} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/profile" component={Profile} />
              <Route path="/message" component={BoardMessage} />
              <Route path="/message_list" component={BoardMessageList} />
              <Route path="/send_campaign" component={BoardCampaign} />
              <Route path="/campaign_list" component={BoardCampaignList} />
            </Switch>
          </div>

          { /*<AuthVerify logOut={this.logOut}/> */ }
        </div>
    );
  }
}

export default App;