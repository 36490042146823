import React, { Component,useState } from "react";
import UserService from "../services/user.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import MessageService from "../services/message.service";
import AuthService from "../services/auth.service";
import EventBus from "../common/EventBus";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import BoostForm from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import CampaignService from "../services/campaign.service";

const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

function AlertDismissible({ totalMsg, sendCount }) {
    return (
        <Alert show={true} variant="success">
            <Alert.Heading>Campaign Started Successfully</Alert.Heading>
            {/*<p>*/}
            {/*    Total Messages : {totalMsg}*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*    Sent Messages : {sendCount}*/}
            {/*</p>*/}
            <hr />
            <div className="d-flex justify-content-end">
                <Button onClick={() => window.location.reload()} variant="outline-success">
                    New Campaign +
                </Button>
            </div>
        </Alert>
    );
}

function showErrorAlert({ totalMsg, sendCount }) {
    return (
        <Alert show={true} variant="danger">
            <Alert.Heading>Campaign Not Sent</Alert.Heading>
            <p>
                Total Messages : {totalMsg}
            </p>
            <p>
                Sent Messages : {sendCount}
            </p>
            <hr />
            <div className="d-flex justify-content-end">
                <Button onClick={() => window.location.reload()} variant="outline-success">
                    New Campaign +
                </Button>
            </div>
        </Alert>
    );
}

function SessionError() {
    return (
        <Alert show={true} variant="danger">
            <Alert.Heading>Session Timed out.Please login again..</Alert.Heading>
            <hr />
            <div className="d-flex justify-content-end">
                <Button onClick={() => window.location.reload()} variant="outline-success">
                    Login
                </Button>
            </div>
        </Alert>
    );
}

export default class BoardCampaign extends Component {

    constructor(props) {
        super(props);
        this.handleMessageSend = this.handleMessageSend.bind(this);
        this.onChangeURL = this.onChangeURL.bind(this);
        this.onChangeCampaignName = this.onChangeCampaignName.bind(this);
        this.onChangeMessageText = this.onChangeMessageText.bind(this);
        this.onChangeCampaignTarget = this.onChangeCampaignTarget.bind(this);
        this.onChangeCampaignGroup = this.onChangeCampaignGroup.bind(this);
        this.logOut = this.logOut.bind(this);

        this.state = {
            content: "",
            url: "",
            campaignName: "",
            campaignGroup: "",
            campaignTarget:"",
            messageText: "",
            loading: false,
            msgLength: 0,
            msgPreview: "",
            isSent: false,
            isSentError: false,
            totalMsgCount: 0,
            sentCount: 0,
            isSessionError: false,
            listSize: 0,
            isListLoading: false
        };
    }

    logOut() {
        AuthService.logout();
        this.setState({
            showModeratorBoard: false,
            showAdminBoard: false,
            currentUser: undefined,
        });
        this.props.history.push("/login?path=send_campaign");
        window.location.reload();
    }

    componentDidMount() {
        UserService.getUserBoard().then(
            response => {
                this.setState({
                    content: response.data
                });
            },
            error => {
                this.setState({
                    content:
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString()
                });

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                    this.props.history.push("/login?path=send_campaign");
                }
            }
        );
    }

    onChangeURL(e){

        let totalLength = e.target.value.length+this.state.messageText.length;
        let msgTxtPreview = this.state.messageText+"<p>"+e.target.value+"</p><p>*SMS STOP Lassana to 0762831983</p>";

        this.setState({
            url: e.target.value,
            msgLength: totalLength,
            msgPreview : msgTxtPreview
        });
    }

    onChangeCampaignName(e){
        this.setState({
            campaignName: e.target.value
        });
    }

    onChangeCampaignGroup(e){
        this.setState({
            campaignGroup: e.target.value
        });
        if(this.state.campaignTarget != ""){
            this.getListSize(e.target.value,this.state.campaignTarget);
        }
    }


    onChangeCampaignTarget(e){
        this.setState({
            campaignTarget: e.target.value
        });
        if(this.state.campaignGroup != ""){
            this.getListSize(this.state.campaignGroup,e.target.value);
        }
    }

    onChangeMessageText(e){

        let totalLength = e.target.value.length+this.state.url.length;
        let msgTxtPreview = e.target.value+"<p>*SMS STOP Lassana to 94776655077</p>";

        this.setState({
            messageText: e.target.value,
            msgLength: totalLength,
            msgPreview:msgTxtPreview
        });

    }

    getListSize(group,geo){

        this.setState({
            isListLoading: true
        });

        CampaignService.getCampaignListSize(group,geo).then(
            response => {
                if (response.data.status === "SUCCESS") {
                    this.setState({
                        listSize: response.data.data,
                        isListLoading: false
                    });
                }
            },
            error => {
                this.setState({
                    isListLoading: false
                });
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                this.setState({
                    message: resMessage
                });
                console.log(resMessage);
                if (resMessage.indexOf("code 401") != -1) {
                    this.setState({
                        isSessionError: true
                    });
                }
            }
        )
    }


    handleMessageSend(e){
        e.preventDefault();
        this.form.validateAll();
        if (this.checkBtn.context._errors.length === 0) {
            if(this.state.messageText.length > 0) {
                if(!(this.state.campaignGroup === "-1") && !(this.state.campaignTarget === "-1")) {

                    const user = AuthService.getCurrentUser();
                    this.setState({
                        message: "",
                        loading: true
                    });
                    CampaignService.sendCampaign(this.state.campaignName, this.state.messageText, this.state.url, this.state.campaignGroup, this.state.campaignTarget).then(
                        response => {
                            console.log(response.data);
                            if (response.data.status === "SUCCESS") {
                                this.setState({
                                    loading: false,
                                    isSent: true
                                    //totalMsgCount: response.data.data.totalMsg,
                                    //sentCount: response.data.data.sentCount
                                });
                            } else {
                                this.setState({
                                    loading: false,
                                    isSentError: true,
                                    message: response.data.msg
                                });
                            }
                        },
                        error => {
                            const resMessage =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                            this.setState({
                                loading: false,
                                message: resMessage
                            });
                            if (resMessage.indexOf("code 401") != -1) {
                                this.setState({
                                    isSessionError: true
                                });
                            }
                        }
                    );
                }
            }
        }
    }

    render() {
        return (
            <div className="container">
                <header className="jumbotron">
                    {/*<h3>Send Message</h3>*/}
                </header>
                <div className="col-md-12">
                    <div className="card card-container">
                        <img
                            src="//cdn-icons-png.flaticon.com/512/234/234129.png"
                            alt="profile-img"
                            className="profile-img-card"
                        />
                        {this.state.isSessionError && (
                            <SessionError />
                        )}
                        {this.state.isSent && (
                            <AlertDismissible
                                totalMsg={this.state.totalMsgCount}
                                sendCount={this.state.sentCount}/>
                        )}
                        {this.state.isSentError && (
                            <showErrorAlert
                                totalMsg={this.state.totalMsgCount}
                                sendCount={this.state.sentCount}/>
                        )}
                        {!this.state.isSent && !this.state.isSessionError && !this.state.isSentError && (
                            <Form
                                onSubmit={this.handleMessageSend}
                                ref={c => {
                                    this.form = c;
                                }}
                            >
                                <div className="form-group">
                                    <label htmlFor="campaignName">Campaign Name</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="orderNo"
                                        value={this.state.campaignName}
                                        onChange={this.onChangeCampaignName}
                                        validations={[required]}
                                    />
                                </div>
                                <br/>
                                <div className="form-group">
                                    <label htmlFor="messageTxt">Campaign Text</label>
                                    <textarea
                                        className="form-control"
                                        name="messageTxt"
                                        value={this.state.messageText}
                                        onChange={this.onChangeMessageText}
                                    />
                                </div>
                                <br/>
                                {/*<div className="form-group">*/}
                                {/*    <label htmlFor="url">Campaign URL</label>*/}
                                {/*    <textarea*/}
                                {/*        className="form-control"*/}
                                {/*        name="url"*/}
                                {/*        value={this.state.url}*/}
                                {/*        onChange={this.onChangeURL}*/}
                                {/*    />*/}
                                {/*</div>*/}
                                <br/>
                                <div className="form-group">
                                    <label htmlFor="campaignGroup">Campaign Group</label>
                                    <BoostForm.Select
                                        aria-label="Campaign Group"
                                        value={this.state.campaignGroup}
                                        onChange={this.onChangeCampaignGroup}
                                    >
                                        <option value="-1">-Select Group-</option>
                                        <option value="GIFTING">Gifting</option>
                                        <option value="ESSENTIAL">Essential</option>
                                        <option value="TEST">Test</option>
                                        <option value="ALL">All</option>
                                    </BoostForm.Select>
                                </div>
                                <br/>
                                <div className="form-group">
                                    <label htmlFor="campaignTarget">Campaign Target Geo</label>
                                    <BoostForm.Select
                                        aria-label="Campaign Target"
                                        value={this.state.campaignTarget}
                                        onChange={this.onChangeCampaignTarget}
                                    >
                                        <option value="-1">-Select Target Geo-</option>
                                        <option value="LOCAL">Local</option>
                                        <option value="EXPAT">Expat</option>
                                        <option value="TEST">Test</option>
                                        <option value="ALL">ALL</option>
                                    </BoostForm.Select>
                                </div>
                                <br/>
                                <div className="form-group">
                                    <label htmlFor="textLength">Message Length : {this.state.msgLength}</label>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="textLength" style={{color:"red",fontWeight:700,fontSize: 17}}>
                                        Total Customer Count :
                                        {this.state.isListLoading && (
                                           <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        {!this.state.isListLoading && (
                                            " "+this.state.listSize
                                        )}
                                    </label>
                                </div>
                                <div className="form-group">
                                        <Card>
                                            <Card.Body>
                                                <Card.Text dangerouslySetInnerHTML={{__html: this.state.msgPreview}}>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                </div>
                                <br/>
                                <div className="form-group" style={{textAlign:"center"}}>
                                    <button
                                        className="btn btn-danger btn-block"
                                        disabled={this.state.loading}
                                    >
                                        {this.state.loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        &nbsp;
                                        <span>Send Message</span>
                                    </button>
                                </div>
                                {this.state.message && (
                                    <div className="form-group">
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.message}
                                        </div>
                                    </div>
                                )}
                                <CheckButton
                                    style={{ display: "none" }}
                                    ref={c => {
                                        this.checkBtn = c;
                                    }}
                                />
                            </Form>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}