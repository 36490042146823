import React, { Component } from "react";
import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import CampaignService from "../services/campaign.service";
import DataTable from 'react-data-table-component';



export default class BoardCampaignList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: "",
            campaignList:[]
        };
    }

    componentDidMount() {
        UserService.getAdminBoard().then(
            response => {
                this.setState({
                    content: response.data
                });
            },
            error => {
                this.setState({
                    content:
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString()
                });

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                    this.props.history.push("/login?path=campaign_list");
                }
            }
        );

        CampaignService.getAllCampaigns().then(
            response => {
                this.setState({
                    campaignList: response.data.data
                });
            },
            error => {
            });

    }




    render() {

        const i = 0;

        function formatDate(date) {
            let d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            let amPm = "AM";
            if(d.getHours() > 12){
                amPm = "PM";
            }

            return [year, month, day].join('-')+" "+(d.getHours()-12)+":"+d.getMinutes()+" "+amPm;
        }

        const columns = [
            {
                name: 'Name',
                selector: row => row.campaignName,
            },
            {
                name: 'Text',
                selector: row => row.campaignText,
                wrap: true,
                format: row => `${row.campaignText.slice(0, 20)}...`
            },
            {
                name: 'URL',
                selector: row => row.campaignURL,
            },
            {
                name: 'Code',
                selector: row => row.campaignCode,
            },
            {
                name: 'Group',
                selector: row => row.campaignGroup,
            },
            {
                name: 'Campaign Geo',
                selector: row => row.campaignTargetGeo,
            },
            {
                name: 'Total Messages',
                selector: row => row.totalMsgCount,
                sortable: true,

            },
            {
                name: 'Sent Count',
                selector: row => row.sentMsgCount,
                sortable: true,

            },
            {
                name: 'Sent Date Time',
                selector: row => row.dateUpdated,
                sortable: true,
                format: row => `${formatDate(Date.parse(row.dateUpdated))}`,
                width: "150px"
            },

        ];

        const ExpandedComponent = ({ data }) => <pre><b>Sent Text</b> - {data.campaignText}<br/><b>Sent URL</b> - {data.campaignURL}</pre>;

        return (
            <div className="container">
                <header className="jumbotron">
                    <h3>Sent Campaigns Dashboard</h3>
                </header>
                <div>
                    <DataTable
                        expandableRows
                        expandableRowsComponent={ExpandedComponent}
                        columns={columns}
                        data={this.state.campaignList}
                        dense
                        pagination
                    />
                </div>
            </div>
        );
    }
}